import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import timeBg from '../../timeBg.jpg'

var splendoBlue = '#2F76CA'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${timeBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      padding: 0;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 100px 0;
  @media (max-width: 800px) {
    text-align: center;
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    border-radius: 12px;
    background: ${splendoBlue};
    text-transform: uppercase;
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />

    <Hero>
      <div className="wrapper">
        <h1>Time Series Deep Learning Model</h1>
      </div>
    </Hero>

    <Details>
      <h1 className="sectionHeader">About Our Time Series Model</h1>
      <p>
        Splendo develops generic deep learning models for timeseries data. Many data sources are never 'complete' because new data points are
        continuously added. Splendo is able to convert such data into a so-called 'time series stream', after which predictions can be made with this
        data using deep learning models.
      </p>
    </Details>

    <Footer />
  </Container>
)
